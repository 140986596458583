import { createRoot } from 'react-dom/client';
import OtherSection from './integration/others/OtherSection';
import SectionWithTabs from '../components/shared/sections/SectionsWithTabs';

const Integration = () => {
  const sectionTabs = [{ value: 'others', label: 'Outras', component: <OtherSection /> }];

  return <SectionWithTabs tabs={sectionTabs} tabsHidden={true} />;
};

const integrationContainer = document.getElementById('integration-container');

if (integrationContainer !== null) {
  const root = createRoot(integrationContainer);

  root.render(<Integration />);
}
