import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import Paragraph from '../typography/Paragraph';

const MainTooltip = ({ ...props }: TooltipProps) => {
  return (
    <>
      {props.title ? (
        <Tooltip
          arrow
          {...props}
          title={<Paragraph sx={{ color: '#fff', fontSize: '12px' }}>{props.title}</Paragraph>}
        />
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};

export default MainTooltip;
