import { Box, Card, CardActions, CardContent, CardMedia } from '@mui/material';
import React from 'react';
import Paragraph from '../typography/Paragraph';
import SecondaryLink from '../links/SecondaryLink';

interface InfoCardProps {
  title: string;
  subtitle?: string;
  img: any;
  linkTitle: string;
  linkHref: string;
  linkOptions?: {
    targetBlank?: boolean;
    thirdPartyLink?: boolean;
  };
}

const InfoCard: React.FC<InfoCardProps> = ({ title, subtitle, img, linkTitle, linkHref, linkOptions }) => {
  return (
    <Card
      sx={{
        padding: '24px',
        backgroundColor: '#FFF',
        border: '1px solid #E0E0E0',
        borderRadius: '8px',
        boxShadow: 'unset',
      }}
    >
      <CardContent sx={{ padding: 0, marginBottom: '16px' }}>
        <Paragraph sx={{ fontSize: '16px', marginBottom: '4px' }}>
          <strong>{title}</strong>
        </Paragraph>
        <Paragraph>{subtitle}</Paragraph>
      </CardContent>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <CardMedia
          component="img"
          image={img}
          sx={{ 'border-radius': '8px', height: '90px', width: '90px', objectFit: 'contain' }}
        />
        <CardActions sx={{ padding: 0 }}>
          <SecondaryLink href={linkHref} {...linkOptions}>
            {linkTitle}
          </SecondaryLink>
        </CardActions>
      </Box>
    </Card>
  );
};

export default InfoCard;
