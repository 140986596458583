import { Box } from '@mui/material';
import React from 'react';

interface SectionTabProps {
  children?: React.ReactNode;
  currentValue?: number;
  index?: number;
  other?: any;
}

const SectionTab: React.FC<SectionTabProps> = ({ ...props }) => {
  const { children, currentValue, index, ...other } = props;
  let hidden = false;

  if (currentValue || index) {
    hidden = currentValue !== index;
  }

  const boxSx = {
    margin: '16px 0 60px',
    padding: '48px 0px',
    backgroundColor: '#FFF',
    boxShadow: '0px 8px 10px 0px rgba(0, 0, 0, 0.20), 0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
  };

  return (
    <section
      role="tabpanel"
      hidden={hidden}
      id={`integration-tabpanel-${index}`}
      aria-labelledby={`integration-tab-${index}`}
      {...other}
    >
      {!hidden && (
        <Box sx={boxSx}>
          <Box sx={{ maxWidth: '90%', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>{children}</Box>
        </Box>
      )}
    </section>
  );
};

export default SectionTab;
