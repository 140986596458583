import React from 'react';
import { Alert, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import apiImage from '@/images/api_image.jpg';
import zapierImage from '@/images/zapier_image.png';
import InfoBox from '../../../components/shared/boxes/InfoBox';
import MainSubtitle from '../../../components/shared/typography/MainSubtitle';
import Paragraph from '../../../components/shared/typography/Paragraph';
import MainSubsectionTitle from '../../../components/shared/typography/MainSubsectionTitle';
import SecretToken from '../../../components/shared/others/SecretToken';
import TokenGenerator from './TokenGenerator';
import SecondaryLinkButton from '../../../components/shared/links/SecondaryLinkButton';
import InfoCard from '../../../components/shared/boxes/InfoCard';

const OtherSection = () => {
  const [integrationData, setIntegrationData] = useState({});
  const [permissions, setPermissions] = useState({});
  const [apiError, setApiError] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/integrations.json');

      const contentType = response.headers.get('content-type');

      if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError("Oops, we haven't got JSON!");
      }

      const responseJson = await response.json();

      if (!response.ok) {
        const errorMessage = `Error ${response.status}: ${responseJson.error}`;
        return setApiError({
          errorMessage: errorMessage,
        });
      }

      const responsePermissions = {};
      responseJson.links.forEach((link) => {
        responsePermissions[link.rel] = { ...link, available: true };
      });

      setIntegrationData(responseJson);
      setPermissions(responsePermissions);
    };

    fetchData();

    return () => {};
  }, []);

  const accountPlanCannotIntegrate = permissions['plan_cannot_integrate']?.available || false;
  const showTokenCreatorButton = accountPlanCannotIntegrate || permissions['create_user_api_token']?.available;

  const updateTokens = ({ response, responseJson }) => {
    const register = responseJson?.values?.device?.register;
    const authToken = responseJson?.values?.device?.authentication_token;

    if (!response.ok) {
      const errorMessage = `Error ${response.status}: ${responseJson.error}`;
      return setApiError({
        errorMessage: errorMessage,
      });
    }

    if (!register || !authToken) {
      return;
    }

    const oldCreateUserApiToken = permissions['create_user_api_token'] || {};

    setIntegrationData({
      ...integrationData,
      produttivo_api_authentication_token: authToken,
      produttivo_api_register: register,
    });

    setPermissions({
      ...permissions,
      create_user_api_token: {
        ...oldCreateUserApiToken,
        available: false,
      },
    });
  };

  const mainBoxSx = {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  };

  return (
    <Box sx={mainBoxSx}>
      {accountPlanCannotIntegrate && (
        <InfoBox
          title={'As chaves de acesso para integração com a API estão inclusas no Plano Automação.'}
          link={{ title: 'Acessar Meu Plano', href: `${permissions['plan_cannot_integrate']?.href}` }}
        />
      )}

      {apiError.errorMessage && (
        <Alert severity="error" onClose={() => setApiError({})}>
          {apiError.errorMessage}
        </Alert>
      )}

      <Box>
        <MainSubtitle sx={{ marginBottom: '8px' }}>Outras integrações</MainSubtitle>
        <Paragraph>
          É possível integrar o Produttivo com qualquer outro software através das chaves de acesso para a nossa API
          REST e com isso personalizar ainda mais a ferramenta.
        </Paragraph>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', paddingBottom: '16px' }}>
        <MainSubsectionTitle sx={{ marginBottom: '4px' }}>Chaves de acesso</MainSubsectionTitle>
        <Box>
          <Paragraph sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
            <strong>Login: </strong>
            {integrationData['user_email']}
          </Paragraph>
        </Box>
        <Box sx={{ maxWidth: '416px' }}>
          <SecretToken
            title={'Authentication token'}
            token={integrationData['produttivo_api_authentication_token']}
            disabled={accountPlanCannotIntegrate}
          />
        </Box>
        <Box sx={{ maxWidth: '416px', marginBottom: '8px' }}>
          <SecretToken
            title={'Device token'}
            token={integrationData['produttivo_api_register']}
            disabled={accountPlanCannotIntegrate}
          />
        </Box>

        <Box>
          {Object.keys(integrationData).length > 0 && (
            <>
              {showTokenCreatorButton ? (
                <TokenGenerator
                  apiInfo={{
                    url: permissions['create_user_api_token']?.href,
                    methodType: permissions['create_user_api_token']?.type,
                  }}
                  buttonDisabled={accountPlanCannotIntegrate}
                  updateTokensCallback={updateTokens}
                  buttonTitle={'Gerar chaves de acesso'}
                />
              ) : (
                <SecondaryLinkButton href="/api-docs" target="_blank">
                  Acessar documentação de requisição
                </SecondaryLinkButton>
              )}
            </>
          )}
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <InfoCard
          title="Via API"
          subtitle="Será necessário que um time de desenvolvimento, área de TI ou fornecedor de software desenvolva a integração."
          img={apiImage}
          linkTitle="Saiba mais"
          linkHref="https://help.produttivo.com.br/pt-BR/articles/6396058-primeiros-passos-para-integracao-via-api"
          linkOptions={{
            targetBlank: true,
            thirdPartyLink: true,
          }}
        />
        <InfoCard
          title="Via Zapier"
          subtitle="É possível integrar o Produttivo com mais de 750 aplicações através do Zapier, um programa que permite que você conecte ferramentas da web sem precisar de conhecimentos em programação."
          img={zapierImage}
          linkTitle="Saiba mais"
          linkHref="https://help.produttivo.com.br/pt-BR/articles/1761011-integracao-com-outros-softwares-atraves-do-zapier"
          linkOptions={{
            targetBlank: true,
            thirdPartyLink: true,
          }}
        />
      </Box>
    </Box>
  );
};

export default OtherSection;
