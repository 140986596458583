import { useCallback } from 'react';
import PrimaryButton from '../../../components/shared/buttons/PrimaryButton';

const TokenGenerator = ({ apiInfo, buttonDisabled, updateTokensCallback, buttonTitle }) => {
  const methodType = apiInfo.methodType;
  const body = apiInfo.body;

  const apiParams = {
    method: methodType,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (body) {
    apiParams.body = body;
  }

  const generateToken = useCallback(async () => {
    try {
      const response = await fetch(apiInfo.url, apiParams);

      const contentType = response.headers.get('content-type');

      if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError("Oops, we haven't got JSON!");
      }

      const responseJson = await response.json();

      updateTokensCallback({ response: response, responseJson: responseJson });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <PrimaryButton
      disabled={buttonDisabled}
      onClick={generateToken}
      disabledText="O plano contratado não te dá acesso a esta funcionalidade."
    >
      {buttonTitle}
    </PrimaryButton>
  );
};

export default TokenGenerator;
