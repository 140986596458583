import React from 'react';
import { Box, Button, ButtonOwnProps } from '@mui/material';
import MainTooltip from '../others/MainTooltip';

interface PrimaryButtonProps extends ButtonOwnProps {
  children: React.ReactNode;
  title: string;
  disabledText?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ ...props }) => {
  const { children, title, disabled, disabledText, sx } = props;

  const buttonSx = {
    display: 'flex',
    alignItems: 'center',
    minHeight: '50px',
    padding: '8px 40px',
    borderRadius: '8px',
    backgroundColor: '#01A32D',
    fontSize: '18px',
    fontWeight: 500,
    inlineHeight: '36px',
    color: '#FFF',
    ':disabled': {
      backgroundColor: 'rgba(1, 163, 45, 0.20)',
      color: '#FFF',
    },
    ':hover': {
      color: '#D0FF26',
      backgroundColor: '#01A32D',
    },
    'text-transform': 'none',
    '@media (max-width: 600px)': {
      minWidth: '64px',
      padding: '8px 16px',
    },
    ...sx,
  };

  const updatedProps = {
    ...props,
    'aria-label': title,
    sx: buttonSx,
  };

  const tooltipTitle = disabled && disabledText ? disabledText : undefined;

  return (
    <MainTooltip title={tooltipTitle}>
      <Box sx={{ display: 'inline-block' }}>
        <Button {...updatedProps}>{children}</Button>
      </Box>
    </MainTooltip>
  );
};

export default PrimaryButton;
