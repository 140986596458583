import { Typography, TypographyOwnProps } from '@mui/material';
import React from 'react';

interface MainSubtitleProps {
  children: React.ReactNode;
  sx?: TypographyOwnProps['sx'];
}

const MainSubtitle: React.FC<MainSubtitleProps> = ({ children, sx }) => {
  const typographySx = {
    color: '#01A32D',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '24px',
    fontFamily: 'Roboto',
    ...sx,
  };

  return (
    <Typography variant="h2" sx={typographySx}>
      {children}
    </Typography>
  );
};

export default MainSubtitle;
