import React, { useState } from 'react';
import { Box, IconButton, SxProps } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Paragraph from '../typography/Paragraph';
import MainTooltip from './MainTooltip';

interface SecretTokenProps {
  title: string;
  token?: string | null;
  disabled?: null | boolean;
}

const SecretToken: React.FC<SecretTokenProps> = ({ title, token, disabled = false }) => {
  const [tokenVisible, setTokenVisible] = useState<boolean>(false);
  const tokenLength = token?.length || 27;
  const tokenMask = Array(tokenLength).fill('*').join('');
  const visibilityArialLabel = tokenVisible ? 'Ocultar' : 'Visualizar';
  const paragraphSx: SxProps | {} = {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
  };

  if (disabled || !token) {
    paragraphSx['color'] = 'rgba(51, 51, 51, 0.20)';
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      <Paragraph sx={paragraphSx}>
        <strong>{title}:</strong>

        {token && tokenVisible ? <span>{token}</span> : <span>{tokenMask}</span>}
      </Paragraph>
      {!disabled && token && (
        <MainTooltip title={visibilityArialLabel} placement="right-start">
          <IconButton
            sx={{
              minWidth: 0,
              padding: 0,
              marginLeft: '24px',
              color: '#333',
            }}
            onClick={() => setTokenVisible(!tokenVisible)}
            aria-label={visibilityArialLabel}
          >
            {tokenVisible ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
          </IconButton>
        </MainTooltip>
      )}
    </Box>
  );
};

export default SecretToken;
