import { Link, LinkOwnProps } from '@mui/material';
import React from 'react';

interface SecondaryLinkProps extends LinkOwnProps {
  href?: string;
  targetBlank?: boolean;
  thirdPartyLink?: boolean;
}

const SecondaryLink: React.FC<SecondaryLinkProps> = ({ ...props }) => {
  const { children, sx, href, targetBlank, thirdPartyLink } = props;
  const linkSx = {
    fontSize: '14px',
    fontWeight: 400,
    color: '#8E8E8E',
    textDecoration: 'underline',
    lineHeight: 'normal',
    ':hover, :active, :focus': {
      color: '#464646',
      textDecoration: 'underline',
    },
    ...sx,
  };

  const linkProps: SecondaryLinkProps = {
    sx: linkSx,
    href: href,
  };

  if (targetBlank) {
    linkProps['target'] = '_blank';
  }

  if (thirdPartyLink) {
    linkProps['rel'] = 'noopener';
  }

  return <Link {...linkProps}>{children}</Link>;
};

export default SecondaryLink;
