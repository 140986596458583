import React from 'react';
import { Box, Link, LinkOwnProps, SxProps } from '@mui/material';
import MainTooltip from '../others/MainTooltip';

interface SecondaryLinkButtonProps extends LinkOwnProps {
  children: React.ReactNode;
  href: string;
  disabled?: boolean;
  disabledText?: string;
}

const SecondaryLinkButton: React.FC<SecondaryLinkButtonProps> = ({ ...props }) => {
  const { children, disabled, disabledText, sx } = props;

  const buttonSx: SxProps | {} = {
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    padding: '8px 24px',
    border: '1px solid #D2D2D2',
    borderRadius: '4px',
    backgroundColor: '#FFF',
    fontSize: '14px',
    fontWeight: 500,
    inlineHeight: '36px',
    color: '#01A32D',
    textDecoration: 'none',
    ':disabled': {
      color: '#D2D2D2',
      textDecoration: 'none',
      'pointer-events': 'none',
    },
    ':hover, :focus, :focus-visible': {
      color: '#01A32D',
      backgroundColor: '#FFF',
      border: '1px solid #01A32D',
      textDecoration: 'none',
    },
    'text-transform': 'none',
    '@media (max-width: 600px)': {
      minWidth: '64px',
      padding: '8px 16px',
    },
    ...sx,
  };

  if (disabled) {
    buttonSx['pointer-events'] = 'none';
    buttonSx['color'] = '#D2D2D2';
  }

  const updatedProps = {
    ...props,
    sx: buttonSx,
  };

  const tooltipTitle = disabled && disabledText ? disabledText : undefined;

  return (
    <MainTooltip title={tooltipTitle}>
      <Box sx={{ display: 'inline-block' }}>
        <Link {...updatedProps}>{children}</Link>
      </Box>
    </MainTooltip>
  );
};

export default SecondaryLinkButton;
